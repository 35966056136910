import React from 'react';

export default function GovernanceActionIcon(props: React.SVGAttributes<any>) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            strokeWidth="1"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.2498 15.232H15.5498V13.832H16.2498H16.25C16.7262 13.832 17.1123 13.4459 17.1123 12.9695V2.34453C17.1123 1.8682 16.7261 1.48203 16.2498 1.48203H8.4373C7.9607 1.48203 7.5748 1.86797 7.5748 2.34426V2.34453V3.04453H6.1748V2.34453V2.34426C6.1748 1.09445 7.18782 0.0820312 8.4373 0.0820312H16.2498C17.4993 0.0820312 18.5123 1.095 18.5123 2.34453V12.9695C18.5123 14.2188 17.4996 15.232 16.25 15.232H16.2498ZM3.7498 19.9195C2.50028 19.9195 1.4873 18.9066 1.4873 17.657V6.71953C1.4873 5.46995 2.50029 4.45703 3.7498 4.45703H11.5623C12.8118 4.45703 13.8248 5.46995 13.8248 6.71953V17.657C13.8248 18.9066 12.8118 19.9195 11.5623 19.9195H3.7498ZM2.8873 17.657C2.8873 18.1334 3.27347 18.5195 3.7498 18.5195H11.5623C12.0386 18.5195 12.4248 18.1334 12.4248 17.657V6.71953C12.4248 6.24317 12.0386 5.85703 11.5623 5.85703H3.7498C3.27346 5.85703 2.8873 6.24317 2.8873 6.71953V17.657ZM4.6123 8.98203H5.3123H6.8748H7.5748V7.58203H6.8748H5.3123H4.6123V8.98203ZM5.3123 12.107H4.6123V10.707H5.3123H9.9998H10.6998V12.107H9.9998H5.3123ZM4.6123 15.232H5.3123H9.9998H10.6998V13.832H9.9998H5.3123H4.6123V15.232ZM9.21855 7.58203H10.7811V8.98203H9.21855V7.58203Z"
                fill="currentColor"
            />
        </svg>
    );
}
