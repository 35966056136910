import(/* webpackMode: "eager" */ "/app/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/nprogress/nprogress.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/vanilla-cookieconsent/dist/cookieconsent.css");
;
import(/* webpackMode: "eager" */ "/app/src/assets/css/globals.css");
;
import(/* webpackMode: "eager" */ "/app/src/assets/css/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layout/SideNav/SideNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layout/TopNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Modals/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/shared/hocs/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/providers/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/atoms/NextNProgress/index.tsx");
